.tabs-layout {
    padding: 1.25rem 0.8rem;
}

.details_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
    grid-auto-rows: minmax(100px, auto);
}

.details_container .card {
    margin: 0;
    // min-height: 150px;
}

.details_container>div:first-child {
    grid-row: 1 / 3;
}

.details_container>div:nth-child(2) {
    grid-column: 2 / 4;
}

.details_container>div:last-child {
    grid-column: 2 / 4;
}

.card_header {
    font-size: 18px;
    font-weight: 600;
}

.details_container .card>div:nth-child(2) {
    margin-top: 1rem;
}

ul.details_tab {
    display: grid;
    gap: 7px;
    font-size: 14px;
    list-style: none;
    margin: 0;
    padding: 0;

}

.details_tab li {
    background-color: #eff3f9;
    border-radius: 3px;
    overflow: hidden;
}

.details_tab li.d-flex>div {
    flex: 1.3;
    padding: 5px;
}

.details_tab li.d-flex>div:first-child {
    flex: 1;
    background: #fe5009;
    color: white;
}

.max-content {
    width: max-content;
}

.poc_voucher_wrapper {
    height: 225px;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 4px;
    margin-right: -11px;
}

// .pocVoucher_wrapper {
//     margin-top: 1.2rem;
//     background: var(--color-white) 0% 0% no-repeat padding-box;
//     box-shadow: 0px 0px 6px var(--color-white-card);
//     border-radius: 6px;
//     padding: 1rem 0;

//     .poc_main {
//         padding: 0 1rem;
//     }

//     .vouchers-section-header {
//         display: flex;
//         align-items: center;
//     }
// }


.basic_info,
.user_data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    margin-bottom: 1.25rem;
}

.basic_info .card_sm div:first-child,
.user_data span {
    color: #fe5000d9;
    font-size: 14px;
}

.basic_info .card_sm:last-child,
.user_data .value {
    font-size: 16px;
}

.card_sm {
    background: #cbcbcb0d 0% 0% no-repeat padding-box;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 6px;
    padding: 0.5rem 0.7rem;
}

.user_data {

    .second_col {
        padding-left: 0.5rem;
    }

    & .first_col,
    & .second_col {
        gap: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.voucher {
    grid-template-columns: 1fr;

    .card_sm:first-child,
    .card_sm._email {
        margin-bottom: 1.25rem;
    }

    a {
        color: #000000;
        word-break: break-word;
    }

    a:hover {
        color: #b23800;
    }

}

.wrapper_r_email {
    display: flex;

    & .form-group {
        margin-right: 0.6rem;
        flex: 2
    }

    & button {
        align-self: flex-start;
        padding: 0.45rem 0.5rem;
    }

}

.pure-table table tbody tr td:has(span img.VM_status) {
    padding: 0.50rem !important;
    padding-right: 0px !important;
    overflow: visible;
}

img.VM_status.ubuntu_scale {
    scale: 1.5;
}

.pos-rel {
    position: relative;
}

.vm_status {
    width: 10px;
    height: 10px;
    margin-left: 6px;
    border-radius: 50%;
    margin-top: 4.5px;
    position: absolute;
    left: 7.8rem;

    &.not_claimed {
        background-color: #808080;
    }

    &.not_ready {
        background-color: #ffff00;
    }

    &.partial_deployment {
        background-color: #ffa500;
    }

    &.not_responding {
        background-color: #ff0000;
    }

    &.ready {
        background-color: #008000;
    }
}