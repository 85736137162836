.pocs-list-section {
  padding: 1.25rem 0.8rem;

  &-header {
    font-size: 1.5rem;
  }

  &-search {
    width: 16rem;
  }

  &-add {
    z-index: 10;
    margin-bottom: 0.2rem;
    width: max-content;
  }

  .more-tags {
    color: var(--color-primary) !important;
  }

  .tag {
    font-size: 0.8rem;
    min-width: 5rem;
    max-width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
}

.nav_link {
  color: black;

  &:hover {
    color: #fe5000;
  }
}

.gap-1 {
  column-gap: 10px;
}

.custom-control.custom-switch:has(#include_closed) {
  display: flex;
  align-items: center;
  margin-right: 14px;

  & label {
    font-size: 16px;
  }
}